import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Swarm</h4>
  <h5></h5>
  <br />
  <br />
  2015 <br />
  Individual
  <br />
  Keywords: creative coding, concurrency and distributed systems, algorithms
    </TextLayout>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/xYN08261-vw?si=qCJ_UpfLZUupYcxB" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <PhotoGallery photos={[{
      src: "../../../swarm/swarm2.png",
      width: 3,
      height: 1
    }, {
      src: "../../../swarm/swarm1.png",
      width: 3,
      height: 1
    }, {
      src: "../../../swarm/swarm3.png",
      width: 3,
      height: 1
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
  <b>Introduction</b>
  <Text mdxType="Text">
    Coordinating behaviours and resources in a concurrent and distributed way is
    at the heart of the project. The code framework which model and simulate a
    swarm of vehicles showing default behaviours which keep them in motion. All
    vehicles have a local “charge” to keep them “alive”. The vehicles replenish
    their charge to full by passing “energy globes” in close proximity. Vehicles
    which run out of charge mysteriously disappear.
    <br />
    <br />
    The overall design goal is to keep as many vehicles alive as possible for as
    long as possible. As energy globes can only be discovered locally, communication
    is required. Here shows how three different algorithms influence the movement
    and the survival rate of vehicles.
    <br />
    <br />
    <a href="https://github.com/void-kuangyi/Gliding-in-Space">Github</a>
  </Text>
    </TextLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      